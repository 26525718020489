import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
import { ItalicText } from '../../components/italic-text/italic-text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Does placeholder text in forms need to meet color contrast requirements? The short answer is: `}<ItalicText mdxType="ItalicText">{`“Yes, it does!”`}</ItalicText></p>
    <p>{`The long answer: Not all users have perfect vision with high contrast sensitivity. Especially when you get older,
it gets harder to recognize text as being separate from the background behind it when color contrast is low.
Therefore, you should always provide sufficient contrast between text and its background.`}</p>
    <p>{`This also applies to placeholder text in web forms or documents. The placeholder usually provides a useful hint
that describes the expected value of an input field. It isn't purely decorative. So, all users should be able to
perceive the placeholder text.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMEBf/EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/aAAwDAQACEAMQAAABhr4N5gHP/8QAHBAAAQMFAAAAAAAAAAAAAAAAAgABAwQQEhNB/9oACAEBAAEFAqUGNTQBj1bpLf/EABcRAAMBAAAAAAAAAAAAAAAAAAIQESH/2gAIAQMBAT8BGXV//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHBAAAQMFAAAAAAAAAAAAAAAAAQACEBEhMmFx/9oACAEBAAY/AirMAOpyr2P/xAAaEAEAAgMBAAAAAAAAAAAAAAABABEhMXFB/9oACAEBAAE/Ib5644QvQIcKCjY0wqBYOo7n/9oADAMBAAIAAwAAABCb7//EABYRAQEBAAAAAAAAAAAAAAAAAAEAMf/aAAgBAwEBPxBAOJv/xAAXEQADAQAAAAAAAAAAAAAAAAABEBEh/9oACAECAQE/EDZi/8QAHRABAAICAgMAAAAAAAAAAAAAAQARITFBUXGB8P/aAAgBAQABPxBAASUXjm9uvFy8vkBKrvEICjCkJKDSNJOGqT4YrS7W5//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "An open book on a table with the word rules clearly visible.",
          "title": "An open book on a table with the word rules clearly visible.",
          "src": "/static/091906ddc8b136341182bd9de249916a/e5166/pexels-egor-komarov-book-rules.jpg",
          "srcSet": ["/static/091906ddc8b136341182bd9de249916a/f93b5/pexels-egor-komarov-book-rules.jpg 300w", "/static/091906ddc8b136341182bd9de249916a/b4294/pexels-egor-komarov-book-rules.jpg 600w", "/static/091906ddc8b136341182bd9de249916a/e5166/pexels-egor-komarov-book-rules.jpg 1200w", "/static/091906ddc8b136341182bd9de249916a/b17f8/pexels-egor-komarov-book-rules.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © Egor Komarov / pexels.com`}</em></p>
    <h2>{`Requirements for minimum color contrast`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/contrast-minimum.html"
      }}>{`Success Criterion 1.4.3 Contrast (Minimum)`}</a>{` of WCAG 2.2
defines requirements for minimum color contrast. Text must have a minimum contrast ratio of 4.5 to 1 with the background, or 3 to 1
contrast ratio if its large text. The understanding document explicitly states:`}</p>
    <blockquote>
      <p parentName="blockquote">{`This Success Criterion applies to text in the page, `}<strong>{`including placeholder text`}</strong>{` and text that is shown when
a pointer is hovering over an object or when an object has keyboard focus.`}</p>
    </blockquote>
    <p>{`There's lots of tools you can use to check the contrast ratio of two color values. I mostly use
the `}<a parentName="p" {...{
        "href": "https://webaim.org/resources/contrastchecker/"
      }}>{`WebAIM Contrast Checker`}</a>{`.`}</p>
    <h2>{`How to style placeholder text in web forms`}</h2>
    <p>{`In most browsers, the default appearance of placeholder text is a light gray color by default. UI libraries also
sometimes use very light colors for placeholder text. This results in placeholder text with poor color contrast.`}</p>
    <p>{`Use the `}<InlineCode mdxType="InlineCode">{`::placeholder`}</InlineCode>{` CSS pseudo-element to define a text color with sufficient color contrast:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`input::placeholder {
    color: #5b5b5b;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`You can also change the font style and weight of your placeholder text. Take a look at my demo where you can compare your
browser's default styling with the applied custom styles:`}</p>
    <iframe title="Style placeholder text" src="https://codepen.io/alexlehner86/embed/raBmOZq?default-tab=result" loading="lazy">
    See the Pen <a href="https://codepen.io/alexlehner86/pen/raBmOZq">
    Style placeholder text</a> by Alexander Lehner (<a href="https://codepen.io/alexlehner86">@alexlehner86</a>)
    on <a href="https://codepen.io">CodePen</a>.
    </iframe>
    <p>{`A small change that highly improves the accessibility of your website. Sometimes it's that easy! 😊`}</p>
    <h2>{`Useful Resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG22/Understanding/contrast-minimum.html"
        }}>{`Understanding Success Criterion 1.4.3 Contrast (Minimum)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/::placeholder"
        }}>{`CSS pseudo-element ::placeholder (MDN)`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      